body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src:url('/public/fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto Thin', sans-serif;
  src:url('/public/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 400;
}